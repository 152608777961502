
export default {
  name: 'BaseNav',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    expand: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: '',
      toggled: false,
    }
  },
  mounted() {
    this.id = Math.random().toString()
  },
  methods: {
    onTitleClick(event) {
      this.$emit('title-click', event)
    },
    closeMenu() {
      this.toggled = false
    },
  },
}
