import { render, staticRenderFns } from "./error.vue?vue&type=template&id=85f22c08"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Heading: require('/build/components/Heading.vue').default,BaseButton: require('/build/components/BaseButton.vue').default})
