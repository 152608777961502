
export default {
  name: 'TheHeader',
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route() {
      this.closeMenu()
    },
  },
  methods: {
    closeMenu() {
      if (this.$refs.navbar) {
        this.$refs.navbar.closeMenu()
      }
    },
  },
}
