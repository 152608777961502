import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faCircle as freeFasFaCircle,
  faGlobe as freeFasFaGlobe
} from '@fortawesome/free-solid-svg-icons'

import {
  faGithub as freeFabFaGithub,
  faLinkedin as freeFabFaLinkedin,
  faReddit as freeFabFaReddit,
  faTwitter as freeFabFaTwitter
} from '@fortawesome/free-brands-svg-icons'

library.add(
  freeFasFaCircle,
  freeFasFaGlobe,
  freeFabFaGithub,
  freeFabFaLinkedin,
  freeFabFaReddit,
  freeFabFaTwitter
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
