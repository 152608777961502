import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _066a5fc0 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _470cef18 = () => interopDefault(import('../pages/authorized.vue' /* webpackChunkName: "pages/authorized" */))
const _37c48a02 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _2ec99ac5 = () => interopDefault(import('../pages/commands.vue' /* webpackChunkName: "pages/commands" */))
const _77742de9 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _7f66a840 = () => interopDefault(import('../pages/github.vue' /* webpackChunkName: "pages/github" */))
const _dd5887f4 = () => interopDefault(import('../pages/invite.vue' /* webpackChunkName: "pages/invite" */))
const _33ab6408 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2c307a07 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _7852f930 = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _1c43d32a = () => interopDefault(import('../pages/premium.vue' /* webpackChunkName: "pages/premium" */))
const _4f0027db = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _58b2c06f = () => interopDefault(import('../pages/status.vue' /* webpackChunkName: "pages/status" */))
const _ac68e254 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _f1e7eebc = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _72ff2dcc = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _15a9ba96 = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _1c82aae5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4fb206d2 = () => interopDefault(import('../pages/logs/_id.vue' /* webpackChunkName: "pages/logs/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _066a5fc0,
    name: "about"
  }, {
    path: "/authorized",
    component: _470cef18,
    name: "authorized"
  }, {
    path: "/callback",
    component: _37c48a02,
    name: "callback"
  }, {
    path: "/commands",
    component: _2ec99ac5,
    name: "commands"
  }, {
    path: "/faq",
    component: _77742de9,
    name: "faq"
  }, {
    path: "/github",
    component: _7f66a840,
    name: "github"
  }, {
    path: "/invite",
    component: _dd5887f4,
    name: "invite"
  }, {
    path: "/login",
    component: _33ab6408,
    name: "login"
  }, {
    path: "/logout",
    component: _2c307a07,
    name: "logout"
  }, {
    path: "/partners",
    component: _7852f930,
    name: "partners"
  }, {
    path: "/premium",
    component: _1c43d32a,
    name: "premium"
  }, {
    path: "/privacy",
    component: _4f0027db,
    name: "privacy"
  }, {
    path: "/status",
    component: _58b2c06f,
    name: "status"
  }, {
    path: "/success",
    component: _ac68e254,
    name: "success"
  }, {
    path: "/support",
    component: _f1e7eebc,
    name: "support"
  }, {
    path: "/terms",
    component: _72ff2dcc,
    name: "terms"
  }, {
    path: "/welcome",
    component: _15a9ba96,
    name: "welcome"
  }, {
    path: "/",
    component: _1c82aae5,
    name: "index"
  }, {
    path: "/logs/:id?",
    component: _4fb206d2,
    name: "logs-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
