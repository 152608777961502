import Vue from 'vue';

import {
  CollapsePlugin,
  ToastPlugin,
  ModalPlugin,
  VBPopoverPlugin,
  VBTooltipPlugin
} from 'bootstrap-vue';

Vue.use(CollapsePlugin);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

Vue.use(VBPopoverPlugin);
Vue.use(VBTooltipPlugin);
