
export default {
  name: 'Heading',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
